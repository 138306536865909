@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

.kitchen-background-img {
  object-fit: cover;
  object-position: 100% 0;
  width: 100px;
  height: 137px; }

.product-img {
  width: 100%; }

.cta-btn {
  padding-top: 1rem; }


// Set your brand colors
//$purple: #8a4d76
//$pink: #fa7c91
//$brown: #757763
//$beige-light: #d0d1cd
//$beige-lighter: #eff0eb

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
//$grey-dark: $brown
//$grey-light: $beige-light
//$primary: $purple
//$link: $pink
//$widescreen-enabled: false
//$fullhd-enabled: false

// Update some of Bulma's component variables
//$body-background-color: $beige-lighter
//$control-border-width: 2px
//$input-border-color: transparent
//$input-shadow: none

// Import only what you need from Bulma
@import '../node_modules/bulma';
